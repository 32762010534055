// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Heading, Alert, Media, MediaSection, Icon, Button } from '@plesk/ui-library';
import { Locale } from 'jsw';
import Cookie from 'common/cookie';

const Translate = Locale.getTranslate('components.forms.login');

const MOBILE_APPLICATION_PROMOTION_COOKIE_NAME = 'mobile-application-promotion-hidden';

const LoginMobileApplicationPromotion = ({ baseClassName, className, name, url, icon }) => {
    const [isVisible, setIsVisible] = useState(Boolean(Cookie.get(MOBILE_APPLICATION_PROMOTION_COOKIE_NAME) !== '1'));

    const handleHide = () => {
        setIsVisible(false);
        Cookie.setPermanent(MOBILE_APPLICATION_PROMOTION_COOKIE_NAME, '1', '/');
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Alert
            intent="info"
            className={classNames(baseClassName, className)}
        >
            <Media
                title={<Heading level={4}>{name}</Heading>}
                image={<Icon className={`${baseClassName}__icon`} src={icon || require('icons/64/plesk/plesk.png')} size="64" />}
            >
                <MediaSection>
                    <Translate content="mobileApplicationMessage" params={{ name }} />
                </MediaSection>
                <MediaSection>
                    <Button className={`${baseClassName}__button`} component="a" intent="primary" href={url} onClick={handleHide}>
                        <Translate content="mobileApplicationInstall" />
                    </Button>
                    <Button className={`${baseClassName}__button`} onClick={handleHide}>
                        <Translate content="mobileApplicationHide" />
                    </Button>
                </MediaSection>
            </Media>
        </Alert>
    );
};

LoginMobileApplicationPromotion.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string,
    className: PropTypes.string,
    baseClassName: PropTypes.string,
};

LoginMobileApplicationPromotion.defaultProps = {
    icon: undefined,
    className: undefined,
    baseClassName: 'login-mobile-application-promotion',
};


export default LoginMobileApplicationPromotion;
