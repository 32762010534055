// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import Link from 'components/common/Link';

import BRANDING_QUERY from 'queries/Branding.graphql';

const Brand = ({ baseClassName, className, href }) => {
    const { data: { viewer: { logo: {
        image,
        imageAlt,
        url,
        edition,
    } } } } = useQuery(BRANDING_QUERY);

    const Tag = (url || href) ? Link : 'span';

    return (
        <Tag
            className={classNames(baseClassName, className)}
            to={url || href}
            target={url ? '_blank' : undefined}
        >
            <img className={`${baseClassName}__logo`} src={image} alt={imageAlt} />
            {edition ? <span className={`${baseClassName}__edition`}>{edition}</span> : null}
        </Tag>
    );
};

Brand.propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
    baseClassName: PropTypes.string,
};

Brand.defaultProps = {
    href: undefined,
    className: undefined,
    baseClassName: 'brand',
};

export default Brand;
